<template>
  <div>
    IG160
    {{ data }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: ""
    }
  },
  async created() {
    // this.$Amplify.PubSub.subscribe('myTopic', { provider: 'AWSIoTProvider' }).subscribe({
    // next: data => {
    //     this.data = data.value
    // },
    // error: error => console.error(error),
    // close: () => console.log('Done')
    // });
  },
  computed: {
    
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>

</style>